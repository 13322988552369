<template>
  <div
    v-loading="loading"
    class="supplier wuzq-content"
    element-loading-text="提交中..."
  >
    <el-alert
      title="提示：若以下信息发生变更，请及时联系工作人员修改信息。"
      type="warning"
      show-icon
    />
    </el-alert>
    <el-form label-width="120px">
      <el-form-item
        label="商家名称"
        required
      >
        <el-input
          v-model="supplier.name"
          placeholder="请输入供应商名称"
          :maxlength="50"
          style="width:200px"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="所属展馆"
        required
      >
        <el-cascader
          v-model="supplier.site"
          :options="aSite"
          :props="siteOptions"
          clearable
          disabled
        />
        <!-- <el-select
          v-model="supplier.site"
          placeholder="请选择展馆"
          filterable
          clearable
          disabled
          style="width:200px"
        >
          <el-option
            v-for="item in aSite"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select> -->
      </el-form-item>
      <el-form-item
        label="联系人"
        required
      >
        <el-input
          v-model="supplier.linkman"
          placeholder="请输入联系人"
          style="width:200px"
          :maxlength="20"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="商家手机号"
        required
      >
        <el-input
          v-model="supplier.telNum"
          placeholder="请输入手机号"
          style="width:200px"
          :maxlength="12"
          disabled
        />
      </el-form-item>
      <!-- <el-form-item label="联系人手机号">
        <el-input
          v-model="supplier.linkmanPhone"
          placeholder="请输入联系人手机号"
          style="width:200px"
          :maxlength="12"
          :disabled="type === 'read'"
        />
      </el-form-item> -->
      <el-form-item label="邮箱(选填)">
        <el-input
          v-model="supplier.email"
          placeholder="请输入联系人邮箱"
          style="width:200px"
          disabled
        />
      </el-form-item>
      <!-- <el-form-item label="退货地址">
        <el-input
          v-model="supplier.address"
          placeholder="请输入退货地址"
          style="width:500px"
          :maxlength="200"
          :disabled="type === 'read'"
        />
      </el-form-item> -->
    </el-form>
    <!-- <el-button
      v-if="type !== 'read'"
      type="primary"
      :disabled="isRead && type === 'edit'"
      style="width:100px;margin-left:70px"
      @click="submitInfo"
    >
      保 存
    </el-button> -->
    <!-- <el-button
      v-if="type !== 'read'"
      type="primary"
      :disabled="isRead && type === 'edit'"
      style="width:100px;margin-left:20px"
      plain
      @click="closeSubmit"
    >
      取 消
    </el-button> -->
    <div
      v-if="type === 'read'"
      class="infoList"
    >
      <el-table
        :data="tableData"
        border
        :header-cell-style="{background: '#F0F2F8'}"
        style="width: 100%"
      >
        <el-table-column
          prop="user_name"
          label="操作者"
          width="150"
        />
        <el-table-column
          prop="create_at"
          label="操作时间"
          width="300"
        />
        <el-table-column
          prop="reason"
          label="操作内容"
        />
      </el-table>
    </div>
    <el-dialog
      title="输入取消发货理由"
      :visible.sync="closeTip"
      size="tiny"
    >
      <textarea
        v-model="reason"
        placeholder="请输入取消发货理由"
        :maxlength="100"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeTip = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitInfo('no')"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Editsupplier',
  data() {
    return {
      type: '', // 类型  add  edit  read
      supplier: {
        name: '',
        telNum: '', // 供应商手机号
        address: '', // 退货
        radio: 2, // 1 是  2否   是否主动发货
        site: '', // 站点ID
        linkmanPhone: '', // 联系人手机号
        email: '', // 邮箱
        linkman: '', // 联系人
        bankman: '', // 收款人
        bankCode: '', // 收款帐号
        bankAddress: '', // 收款开户银行
        closeType: '', // 结算类型
        isErp: '' // 是否ERP发货
      },
      loading: false, // 加载动画
      tableData: [], // 日志列表信息
      url: '', // 保存链接
      closeTip: false, // 添加停用发货理由
      tips: false, // 用来判断 是否显示closeTip
      reason: '', // 停用理由
      isRead: false,
      bRead: false,
      aSite: [],
      siteOptions: {
        checkStrictly: true,
        label: 'title',
        value: 'id',
        children: 'rows'
      }
    }
  },
  watch: {
    supplier: {
      handler(curVal, oldVal) {
        if (this.type === 'edit' && this.bRead) {
          this.isRead = false
        }
      },
      deep: true
    }
  },
  created() {
    this.type = this.$route.query.type
  },
  mounted() {
    this.getSelectInfo()
    this.getsiteList()
    if (this.type === 'add') {
      this.url = this.$api.supplier.add
    } else {
      this.url = this.$api.supplier.update
    }
  },
  methods: {
    getSelectInfo() {
      let _this = this
      _this.$axios({
        method: 'post',
        url: _this.$api.getStoreList
      }).then(res => {
        const r = res || {}
        if (r.error === 0) {
          const list = (r.data && r.data.data) || []
          _this.aSite = _this.aSite.concat(list)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getsiteList() {
      let _this = this
      _this.$axios.get(_this.$api.supplier.get, {
        params: {
          id: _this.$route.query.id
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          _this.supplier.name = r.data.suppliers_name
          _this.supplier.telNum = r.data.suppliers_phone
          _this.supplier.address = r.data.suppliers_address
          _this.supplier.site = r.data.site_id
          _this.tableData = r.data.log
          _this.supplier.linkman = r.data.suppliers_contacts
          _this.supplier.linkmanPhone = r.data.phone
          _this.supplier.email = r.data.email
          // _this.supplier.bankman = r.data.payee;
          // _this.supplier.bankCode = r.data.bank_account;
          // _this.supplier.bankAddress = r.data.bank;
          _this.supplier.closeType = r.data.type * 1
          setTimeout(() => {
            _this.bRead = true
          }, 500)
          _this.isRead = true
        }
      })
    },
    submitInfo(type = '') {
      let _this = this
      if (this.tips && type !== 'no') {
        this.closeTip = true
        return null
      }
      if (this.supplier.closeType === '') {
        return this.$message.error('请选择结算类型')
      }
      _this.loading = true
      _this.$axios.post(_this.url, {
        id: _this.$route.query.id || undefined,
        suppliers_name: _this.supplier.name,
        suppliers_phone: _this.supplier.telNum,
        suppliers_address: _this.supplier.address,
        suppliers_reason: _this.reason || undefined,
        suppliers_contacts: _this.supplier.linkman,
        site_id: _this.supplier.site,
        phone: _this.supplier.linkmanPhone,
        email: _this.supplier.email,
        // payee: _this.supplier.bankman,
        // bank_account: _this.supplier.bankCode,
        // bank: _this.supplier.bankAddress,
        type: _this.supplier.closeType
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          _this.$router.push('/store/supplier')
          _this.$message.success('保存成功')
        }
        _this.loading = false
      })
    },
    closeSubmit() {
      this.$confirm('您还有修改后的内容未保存, 是否确定要取消?', '取消编辑提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'info',
          message: '取消成功!'
        })
        this.$router.push('/store/supplier')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '可继续编辑!'
        })
      })
    }
  }
}
</script>

<style scoped>
.el-radio-group{
  padding-top:6px;
}
textarea {
  display: block;
  border: 1px solid #354053;
  resize: none;
  height: 150px;
  padding: 10px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
}
</style>
<style>
.wuzq-content .el-alert {
  margin-bottom: 30px;
  padding: 20px;
  width: 700px;
}
.wuzq-content .el-icon-warning {
  font-size: 22px;
  margin-right: 6px;
}
.wuzq-content .el-alert__title {
  font-size: 16px;
  color: #333;
}
</style>
