var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "supplier wuzq-content",
      attrs: { "element-loading-text": "提交中..." },
    },
    [
      _c("el-alert", {
        attrs: {
          title: "提示：若以下信息发生变更，请及时联系工作人员修改信息。",
          type: "warning",
          "show-icon": "",
        },
      }),
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商家名称", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请输入供应商名称",
                  maxlength: 50,
                  disabled: "",
                },
                model: {
                  value: _vm.supplier.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "name", $$v)
                  },
                  expression: "supplier.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属展馆", required: "" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.aSite,
                  props: _vm.siteOptions,
                  clearable: "",
                  disabled: "",
                },
                model: {
                  value: _vm.supplier.site,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "site", $$v)
                  },
                  expression: "supplier.site",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请输入联系人",
                  maxlength: 20,
                  disabled: "",
                },
                model: {
                  value: _vm.supplier.linkman,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "linkman", $$v)
                  },
                  expression: "supplier.linkman",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商家手机号", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请输入手机号",
                  maxlength: 12,
                  disabled: "",
                },
                model: {
                  value: _vm.supplier.telNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "telNum", $$v)
                  },
                  expression: "supplier.telNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱(选填)" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入联系人邮箱", disabled: "" },
                model: {
                  value: _vm.supplier.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "email", $$v)
                  },
                  expression: "supplier.email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type === "read"
        ? _c(
            "div",
            { staticClass: "infoList" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    "header-cell-style": { background: "#F0F2F8" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "user_name", label: "操作者", width: "150" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_at",
                      label: "操作时间",
                      width: "300",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "reason", label: "操作内容" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "输入取消发货理由",
            visible: _vm.closeTip,
            size: "tiny",
          },
          on: {
            "update:visible": function ($event) {
              _vm.closeTip = $event
            },
          },
        },
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reason,
                expression: "reason",
              },
            ],
            attrs: { placeholder: "请输入取消发货理由", maxlength: 100 },
            domProps: { value: _vm.reason },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.reason = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.closeTip = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitInfo("no")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }